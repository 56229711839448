body {
    /* font-family: 'Source Sans Pro', sans-serif !important;   */
    font-family: 'Helvetica Neue','Segoe UI', Helvetica, Verdana, sans-serif !important;  
}

/*Brand*/

.navbar-brand {
    padding-bottom: 0.2rem !important;
    padding-top: 0rem !important;
}

.layout-top-nav .wrapper .main-header .brand-image {
    background-color: #ffffff;
}

.brand-text {
    color: #ffffff;
}

.layout-top-nav .wrapper .main-header .container {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

/*Navigation*/

.navbar {
    z-index: 99999;
}

.navbar-white {
    background-color: #ffffff;
}

.navbar {
    padding: .5rem .5rem;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(255,255,255,1);
}

    .navbar-light .navbar-nav .nav-link:hover {
        color: rgba(0,0,0,.7) !important;
    }

.navbar-expand-md .navbar-nav .dropdown-menu .dropdown-item {
    color: #ffffff;
    padding: .25rem 1rem !important;
    font-size: 0.8125rem !important;
}

.navbar-expand-md .navbar-nav .dropdown-item:hover, .dropdown-item:focus {
    background-color: #4bb8d1 !important;
}

.navbar-expand-md .navbar-nav .dropdown-menu .dropdown-divider {
    border-top: 1px solid #4bb8d1;
}

.navbar-expand-md .navbar-nav .dropdown-menu-lg {
    max-width: 300px !important;
    min-width: 280px !important;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    background-color: #17a2b8 !important;
}

    .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-header {
        color: #ffffff;
    }

.main-header .nav-link {
    height: auto;
}

.main-header .navbar-nav .nav-item {
    margin-left: 0.5rem;
    /* font-weight: 100; */
    font-size: 1rem !important;

}

    .main-header .navbar-nav .nav-item .dropdown-menu .nav-item {
        margin-left: 0rem;
    }

.dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    background-color: #4bb8d1;
}

.navbar-light .navbar-nav .nav-link:hover {
    /*color: #f5f5f5 !important;*/
}

/*User Menu*/
.navbar-expand-md .navbar-nav .user-menu {
    background-color: #ffffff !important;
}

    .navbar-expand-md .navbar-nav .user-menu .dropdown-item {
        color: #212529 !important;
    }

        .navbar-expand-md .navbar-nav .user-menu .dropdown-item:focus, .navbar-expand-md .navbar-nav .user-menu .dropdown-item:hover {
            background-color: #ffffff !important;
        }

    .navbar-expand-md .navbar-nav .user-menu .dropdown-divider {
        border-top: 1px solid #e9ecef !important;
        margin: 0.5rem 0rem;
    }

    .navbar-expand-md .navbar-nav .user-menu .dropdown-item label {
        font-size: 0.8rem !important;
        font-weight: 600 !important;
    }

    .navbar-expand-md .navbar-nav .user-menu .dropdown-item .media .media-body .dropdown-item-title {
    }


    .navbar-expand-md .navbar-nav .user-menu .media {
        padding: 0px 15px;
    }

@media (min-width: 1200px) {
    .layout-top-nav .wrapper .main-header .container, .layout-top-nav .wrapper .main-header .container-lg, .layout-top-nav .wrapper .main-header .container-md, .layout-top-nav .wrapper .main-header .container-sm, .layout-top-nav .wrapper .main-header .container-xl {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .layout-top-nav .wrapper .main-header .container, .layout-top-nav .wrapper .main-header .container-lg, .layout-top-nav .wrapper .main-header .container-md, .layout-top-nav .wrapper .main-header .container-sm {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .layout-top-nav .wrapper .main-header .container, .layout-top-nav .wrapper .main-header .container-md, .layout-top-nav .wrapper .main-header .container-sm {
        max-width: 100%;
    }
}

@media (min-width: 576px) {
    .layout-top-nav .wrapper .main-header .container, .layout-top-nav .wrapper .main-header .container-sm {
        max-width: 100%;
    }
}

@media (min-width: 1200px) {
    .layout-top-nav .wrapper .main-header .container {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .layout-top-nav .wrapper .main-header .container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .layout-top-nav .wrapper .main-header .container {
        max-width: 100%;
    }
}
